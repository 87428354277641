.questionNav {
    display: flex;
    justify-content: flex-end;
    margin-right: 35px;
    margin-top: 30px;
}

.titleContainerFirst {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    font-family: 'Times New Roman', Georgia, Times, serif;
}

.titleContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    font-family: 'Times New Roman', Georgia, Times, serif;
}

.surveyMessage {
    font-size: 22px;
    font-family: 'Times New Roman', Georgia, Times, serif;
}

.startModalHeader {
    display: flex;
    justify-content: space-between;
}

.startModalText {
    margin: 0 12px 0 12px;
    font-family: 'Times New Roman', Georgia, Times, serif;
    font-size: large;
}

.startModalBody {
    display: flex;
    flex-direction: column;
    margin: 15px 20px 0 20px;
}

.modalButtonHolder {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 6px;
}

.endModalBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.endModalButton {
    width: 100%;
    display: flex;
    justify-content: center;
}

.confirmButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.surveySectionTitle {
    font-weight: 400 !important;
    font-size: 30px !important;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-bottom: 40px !important;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(194, 194, 194);
}
