
.titleContainer {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    margin-bottom: 30px;
}

.reportTitle {
    font-weight: 400 !important;
    font-size: 30px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.centeredPicture {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0 15px 0;
}

.innerPicture {
    min-width: 275px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 15px;
}

.leftPicture {
    width: 100%;
    display: flex;
    text-align: start;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 0 15px 0;
}

.doublePicture {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 0 15px 0;
}

.reportText {
    width: 100%;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 20px;
    font-style: italic;
    text-align: start;
    padding-left: 10px
}

.reportSubtitle {
    font-weight: 400 !important;
    font-size: 26px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-bottom: 10px !important;
    color: darkred;
}

.title3 {
    font-weight: 400 !important;
    font-size: 22px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-bottom: 10px !important;
}

.titlePourAllerPLusLoin {
    font-weight: 400 !important;
    font-size: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-bottom: 10px !important;
}
.textPourAllerPLusLoin {
    width: 100%;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 16px;
    font-style: italic;
    text-align: start;
    padding-left: 10px
}
