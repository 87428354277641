
.imgContainer {
    margin-top: 40px;
    margin-left: 20%;
    margin-right: 20%;
}

.contentContainer {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 35px;
}

.lightweight {
    font-weight: 400 !important;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-bottom: 0 !important;
}

.subTitle {
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.dropdownContainer {
    margin-bottom: 12px !important;
    width: 100%;
}

.noAccount {
    text-align: center;
    font-style: italic;
    font-size: small;
    margin-top: 10px;
}

input:focus {
    box-shadow: none !important;
}

.errorContent {
    margin-bottom: 0 !important;
}
