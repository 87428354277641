
.surveysTitle {
    font-weight: 400 !important;
    font-size: larger;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-bottom: 0 !important;
}

.topSection {
    padding-top: 20px;
    padding-bottom: 0;
}

.preseImage {
    margin-left: 15px;
    margin-top: 5px;
    margin-right: 15px;
}

.firstCol {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.mainNav {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: solid #e9e9e9 1px !important;
}

.mainContent {
    display: flex;
    justify-content: center;
}

.surveysTitle {
    font-size: 28px !important;
    font-weight: 400 !important;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin: 35px 0 0 30px;
}

.surveySubTitle {
    margin: 8px 30px 0 30px;
    padding-bottom: 10px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    border-bottom: solid 1px #d1d1d1;
}


.surveyCard {
    margin-top: 80px;
}

.reportCard {
    margin-top: 60px;
}

.surveyElement {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 0 8px 0;
    margin: 30px 30px 0 30px;
}

.surveyDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.surveyTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: lighter !important;
    font-size: 22px !important;
    font-style: italic;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-bottom: 0 !important;
}

.modalWhoTitle {
    font-weight: 400 !important;
    font-size: 24px;
    margin-bottom: 5px !important;
}

.helpBlock {
    padding: 10px 0 10px 0;
}