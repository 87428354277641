
table:not(.sticky) {
    margin-top: 40px;
}

.innerSpan {
    color: #3298dc;
}

.subContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.questionTitle {
    display: flex;
    flex-direction: row;
    font-size: 22px;
    font-style: italic;
    font-family: 'Times New Roman', Georgia, Times, serif;
    margin-bottom: 8px;
}

.choiceAnswersButton {
    display: flex;
    justify-content: center;
}

.choiceAnswersTitles {
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: normal;
}

.subQuestions {
    font-weight: normal;
    font-family: 'Times New Roman', Georgia, Times, serif;
    font-size: 18px;
}

.choiceAnswers {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin-bottom: 15px;
}

.noBoxInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
}

.multiContainer {
    display: flex;
    flex-direction: row;
}

.adminField {
    display: flex;
    flex-direction: row;
}
